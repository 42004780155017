import React from 'react';
import './NewsScroller.css'; // Import CSS for styling
import ann from '../New_pics/15th_anniversary/IMG-20240729-WA0018.jpg';
import ann2 from '../New_pics/vc_sajjanar_family/WhatsApp Image 2024-07-29 at 11.48.35_90302d35.jpg';
import ann3 from '../New_pics/boora_venkatesam/2.jpg';
import ann4 from '../New_pics/hero_suman/2.jpg';
import ann5 from '../New_pics/abdul_kalam_award/4.jpg';
import ann6 from '../New_pics/railway_GM/2.jpg';
import ann7 from '../New_pics/gajjela_nagesh/3.jpg';
import ann8 from '../New_pics/jaychandra_ips/2.jpg';

const newsItems = [
  {
    id: 1,
    image: ann,
    
    
  },
  {
    id: 2,
    image: ann2,
    title: 'Latest News 2',
    
  },
  {
    id: 3,
    image: ann3,
    title: 'Latest News 3',
  
  },
  {
    id: 4,
    image:ann4,
    title: 'Latest News 4',
   
  },
  {
    id: 5,
    image:ann5,
    title: 'Latest News 4',
   
  },
  {
    id: 6,
    image:ann6,
    title: 'Latest News 4',
   
  },
  {
    id: 7,
    image:ann7,
    title: 'Latest News 4',
   
  },
  {
    id: 8,
    image:ann8,
    title: 'Latest News 4',
   
  },
];

const NewsScroller = () => {
  return (
    <div className="news-scroller-wrapper">
      <div className="news-scroller">
        {newsItems.map((item, index) => (
          <div key={index} className="news-item">
            <img src={item.image} alt={item.title} />
            <div className="news-content">
             
             
            </div>
          </div>
        ))}
        {newsItems.map((item, index) => (
          <div key={index + newsItems.length} className="news-item">
            <img src={item.image} alt={item.title} />
            <div className="news-content">
          
              
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsScroller;
