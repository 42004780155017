import React from 'react';
import './AdvisorsCommunity.css';
import NewsScroller from '../Home/Carousel';
import img from '../images/temp/chandrasekharreddy.jpg';
import img1 from '../images/temp/venkatesham.jpg';



const advisors = [
    {
        name: "PADMASHREE Dr. Hanuman Chowdary",
        position: "Director - Centre for Telecom Management & Studies Chairman - Pragna Bharati Telangana & Andhra Pradesh",
        formerPosition: "Formerly - IT Adviser, Govt. of AP Dy. Director General - Dept. of Telecoms, Govt. of India",
        image: 'path-to-image1.jpg' // Replace with actual path
    },
    {
        name: "Smt. Minnie Mathew, IAS",
        position: "AP Chief Secretary, Member of AP & Telangana Administrative Tribunal",
        image: 'path-to-image2.jpg' // Replace with actual path
    },
    {
        name: "Sri. Mathew C Kunnumkal, IAS",
        position: "Former Secretary to Govt. of India and the Judicial member of Kerala Administrative Tribunal",
        image: 'path-to-image3.jpg' // Replace with actual path
    },
    {
        name: "Sri. Rama Kotaiah, IRS",
        position: "Commissioner of Income Tax, (Retd)",
        image: 'path-to-image4.jpg' // Replace with actual path
    },
    {
        name: "Sri Kishor Siddamsetty",
        position: "Managing Director, G.K. Properties Pvt. Ltd.",
        image: 'path-to-image5.jpg' // Replace with actual path
    },
    {
        name: "Sri Rajesh Sanghani",
        position: "Managing Director, Unicorn Industries Ltd. A member of the Krones Group Unicorn",
        image: 'path-to-image6.jpg' // Replace with actual path
    },
    {
        name: "Sri. Jonnavithula Raja",
        position: "Chartered Accountant",
        image: 'path-to-image7.jpg' // Replace with actual path
    }
];

const AdvisorsCommunity = () => {
    return (
        <div className="advisors-community">
            <h2>OUR ADVISORS COMMUNITY</h2>
            <div className="advisors-list">
                {advisors.map((advisor, index) => (
                    <div key={index} className="advisor-card">
                        {/* <img src={advisor.image} alt={" "} className="advisor-image" /> */}
                        <div className="advisor-info">
                            <h3>{advisor.name}</h3>
                            <p className="position">{advisor.position}</p>
                            {advisor.formerPosition && <p className="former-position">{advisor.formerPosition}</p>}
                        </div>
                    </div>
                ))}
            </div>
            <img
          className="advisor-image"
          src={img}
          alt="Foundation Logo"
        />
          <img
          className="advisor-image"
          src={img1}
          alt="Foundation Logo"
        />
      
            <div className="news-section">
        <h2>Latest News</h2>
        <NewsScroller />
      </div>
        </div>
    );
};

export default AdvisorsCommunity;
